import React, { useCallback, useEffect } from 'react';

type Props = {
  enableCookies: Boolean,
  id: string,
  title: string,
  activeIndex: number | undefined,

}

const Map = ({ enableCookies, id, title }: Props) => {

  const onScriptLoad = useCallback(() => {
    if (enableCookies) {

      const latLng = { lat: 50.48985, lng: 8.72616 };

      const map = new google.maps.Map(
        document.getElementById(id) as HTMLElement,
        {
          zoom: 16.7,
          center: latLng,
          gestureHandling: "none",
          streetViewControl: false,
          mapTypeControl: false,

        }
      )

      const styles: Record<string, google.maps.MapTypeStyle[]> = {
        default: [],
        hide: [
          {
            featureType: "poi.business",
            stylers: [{ visibility: "off" }],
          },
        ],
      };

      const contentString =
        '<a href="https://maps.google.com/maps?q=35451%20Pohlheim%20Holzheim,%20Zum%20Hasenberg%201" >Kleintierpraxis Anette Jäger</a>';

      map.setOptions({ styles: styles["hide"] });

      const marker = new google.maps.Marker({
        position: latLng,
        map,
      });

      const infowindow = new google.maps.InfoWindow({
        content: contentString,
      });

      infowindow.open({
        anchor: marker,
        map,
      });
    }
  },
    [enableCookies, id],
  );

  useEffect(() => {
    if (enableCookies && !window.google) {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = `https://maps.google.com/maps/api/js?key=AIzaSyAgQ_hAgzQPVW6BsR1D8xOizo_HMONYyGo`;

      const x = document.getElementById(id);
      x?.parentNode?.insertBefore(s, x);

      s.addEventListener('load', () => {
        onScriptLoad();
      })
    } else {
      onScriptLoad()
    }
  },
    [enableCookies, id, onScriptLoad],
  )

  return (
    <div className="map">
      {
        enableCookies ?
          <div className="map-png-container">
            <div
              title={title}
              className="map-active"
              id={id}
            ></div>
          </div>
          :
          <div className="map-png-container">
            <img
              src={require("../css_resources/resources/dog-n-cat.jpg")}
              className="map-png"
              alt=""
            />
          </div>
      }
    </div>
  );
}

export default Map;