import React from 'react';

import TextLoader from '../TextLoader';

import öffnungszeiten from '../css_resources/text/öffnungszeiten.json';

import '../css_resources/css/OpeningHours.css';

type Props = {
    id: string,
};

const OpeningHours = ({ id }: Props) => {

    return (
        <div className="opening-hours" id={id}>
            <div className="opening-hours-container">
                <div className="opening-hours-label">{TextLoader(öffnungszeiten.label, '')}</div>

                <div className="weekday-and-opening-hours-container">
                    <div className="weekday-container">
                        <div className="weekday-and-opening-hours weekday">{TextLoader(öffnungszeiten.sprechzeiten.montag, '', 0, 1)}</div>
                        <div className="weekday-and-opening-hours weekday">{TextLoader(öffnungszeiten.sprechzeiten.dienstag, '', 0, 1)}</div>
                        <div className="weekday-and-opening-hours weekday">{TextLoader(öffnungszeiten.sprechzeiten.mittwoch, '', 0, 1)}</div>
                        <div className="weekday-and-opening-hours weekday">{TextLoader(öffnungszeiten.sprechzeiten.donnerstag, '', 0, 1)}</div>
                        <div className="weekday-and-opening-hours weekday">{TextLoader(öffnungszeiten.sprechzeiten.freitag, '', 0, 1)}</div>
                        <div className="weekday-and-opening-hours weekday">{TextLoader(öffnungszeiten.sprechzeiten.wochenendeUndFeiertage, '', 0, 1)}</div>
                        <div className="weekday-and-opening-hours weekday notdienst">{TextLoader(öffnungszeiten.sprechzeiten.notdienst, '', 0, 1)}</div>
                    </div>
                    <div className="hours-container">
                        <div className="weekday-and-opening-hours">{TextLoader(öffnungszeiten.sprechzeiten.montag, '', 1)}</div>
                        <div className="weekday-and-opening-hours">{TextLoader(öffnungszeiten.sprechzeiten.dienstag, '', 1)}</div>
                        <div className="weekday-and-opening-hours">{TextLoader(öffnungszeiten.sprechzeiten.mittwoch, '', 1)}</div>
                        <div className="weekday-and-opening-hours">{TextLoader(öffnungszeiten.sprechzeiten.donnerstag, '', 1)}</div>
                        <div className="weekday-and-opening-hours">{TextLoader(öffnungszeiten.sprechzeiten.freitag, '', 1)}</div>
                        <div className="weekday-and-opening-hours weekend">{TextLoader(öffnungszeiten.sprechzeiten.wochenendeUndFeiertage, '', 1)}</div>
                        <div className="weekday-and-opening-hours notdienst">
                            {
                                <a href="https://www.tiernotdienst-mittelhessen.de/" >{TextLoader(öffnungszeiten.sprechzeiten.notdienst, '', 1)}</a>
                            }
                        </div>
                    </div>
                </div>
                <div className="cov-info">{TextLoader(öffnungszeiten.cov.info, '', 0, 1)}</div>
            </div>
        </div >
    );
};

export default OpeningHours;







