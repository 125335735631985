const TextLoader = (text: Array<string>, elementId?: string, sN?: number, eN?: number) => {


    let element,
        fullText = '';

    if (elementId !== "" && elementId) {
        element = document.getElementById(elementId);
    }

    let startNumber = sN ? sN : 0;
    let endNumber = eN ? eN : text.length;

    for (let i = startNumber; i < endNumber; i++) {
        if (element !== undefined && element !== null) {
            element.innerHTML += text[i].split('__').join('<br>');
        }
        fullText = text[i];
    }
    return fullText;
}

export default TextLoader;