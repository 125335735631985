import React, { useEffect, useState, useCallback, useMemo, ComponentState } from 'react';
import HamburgerMenu from './HamburgerMenu';

import '../../css_resources/css/SecondHeader.css';
import { TabMenu, TabMenuTabChangeParams } from 'primereact/tabmenu';

type Props = {
  setActiveIndex: ComponentState,
  setActiveComponent: ComponentState,
  activeIndex: number | undefined,
  activeComponent: string,
  enableContent: boolean,
}

const SecondHeader = ({ setActiveIndex, setActiveComponent, activeIndex, activeComponent, enableContent }: Props) => {
  const [menuOpen, setMenuState] = useState(false);

  const items: Array<{ label: string, id: string }> = useMemo(() => {
    return [
      { label: 'Start', id: 'start' },
      { label: 'Aktuelles', id: 'news' },
      { label: 'Öffnungszeiten', id: 'opening-hours' },
      { label: 'Kontakt', id: 'contact' },
    ]
  },
    [],
  );

  const closeMenu = useCallback(() => {
    const secondHeaderContainer = document.getElementById('second-header-container');
    const headerWithTabmenu = document.getElementById('header-with-tabmenu');
    const secondHeader = document.getElementById('second-header');
    const tabmenuContainer = document.getElementById('tabmenu-container');
    const hamburgerButton = document.getElementById('hamburger-button');

    hamburgerButton?.classList.remove('active');
    secondHeaderContainer?.classList.remove('active');
    headerWithTabmenu?.classList.remove('active');
    secondHeader?.classList.remove('active');
    tabmenuContainer?.classList.remove('active');

    setMenuState(false);
  },
    [],
  )

  const closeMenuByScroll = useCallback(() => {
    const firstHeader = document.getElementById('first-header');
    const secondHeaderContainer = document.getElementById('second-header-container');
    const headerWithTabmenu = document.getElementById('header-with-tabmenu');
    const secondHeader = document.getElementById('second-header');
    const tabmenuContainer = document.getElementById('tabmenu-container');
    const label = document.getElementById('label');

    if (document.documentElement.scrollTop >= 20) {
      firstHeader?.classList.add('first-header-invisible');

      secondHeaderContainer?.classList.add('second-header-container-fixed');
      headerWithTabmenu?.classList.add('header-with-tabmenu-fixed');
      secondHeader?.classList.add('second-header-fixed');
      tabmenuContainer?.classList.add('tabmenu-container-fixed');
      label?.classList.add('label-fixed');

      closeMenu();

    } else {
      firstHeader?.classList.remove('first-header-invisible');
      secondHeaderContainer?.classList.remove('second-header-container-fixed');
      headerWithTabmenu?.classList.remove('header-with-tabmenu-fixed');
      secondHeader?.classList.remove('second-header-fixed');
      label?.classList.remove('label-fixed');
      tabmenuContainer?.classList.remove('tabmenu-container-fixed');
    }
  },
    [closeMenu],
  );

  const changeMenuIndex = useCallback(() => {
    const start = document.getElementById('start');
    const news = document.getElementById('news');
    const openingHours = document.getElementById('opening-hours');
    const contact = document.getElementById('contact');

    if (enableContent && start && document.documentElement.scrollTop <= start.scrollHeight - 200) {
      setActiveIndex(0);
      window.location.replace('#' + items[0].id + '/');

    } else if (enableContent && start && news && document.documentElement.scrollTop <= start.scrollHeight + news.scrollHeight - 200) {
      setActiveIndex(1);
      window.location.replace('#' + items[1].id + '/');

    } else if (enableContent && start && news && openingHours && document.documentElement.scrollTop <= start.scrollHeight + news.scrollHeight + openingHours.scrollHeight - 200) {
      setActiveIndex(2);
      window.location.replace('#' + items[2].id + '/');

    } else if (enableContent && start && news && openingHours && contact && document.documentElement.scrollTop <= start.scrollHeight + news.scrollHeight + openingHours.scrollHeight + contact.scrollHeight - 200) {
      setActiveIndex(3);
      window.location.replace('#' + items[3].id + '/');
    }
  },
    [setActiveIndex, enableContent, items],
  );

  const checkElement = useCallback((e: any) => {
    if (e.target.className !== 'p-menuitem-link' && e.target.className !== 'p-menuitem-text'
      && e.target.className !== 'p-tabmenu-nav p-reset' && e.target.id !== 'hamburger-button'
      && !e.target.className.includes('bar')) {
      closeMenu();
      changeMenuIndex();

    }
  },
    [changeMenuIndex, closeMenu],
  );

  useEffect(() => {

    enableContent && (window.location.replace('#' + items[0].id));
    enableContent && (window.location.replace('#' + items[0].id));

    document.addEventListener('scroll', () => {
      closeMenuByScroll();
      changeMenuIndex();
    });
    document.addEventListener('mouseup', (e: MouseEvent) => checkElement(e));

    return () => {
      document.removeEventListener('scroll', () => {
        closeMenuByScroll();
        changeMenuIndex();
      })
      document.removeEventListener('mouseup', (e: MouseEvent) => checkElement(e));
    }
  },
    [checkElement, changeMenuIndex, closeMenuByScroll, enableContent, items],
  );

  return (
    <header className="second-header-container" id="second-header-container" >
      <div className="header-with-tabmenu" id="header-with-tabmenu">
        <div className="second-header" id="second-header">
          <a className="logo-container">
            <img
              src={require("../../css_resources/resources/logo-praxis.png")}
              className="logo"
              alt="logo with dog, cat and mouse"
            />
          </a>
          <a className="label" id="label">
            Kleintierpraxis
            <br></br>
            &nbsp;Anette Jäger
          </a>
          <HamburgerMenu
            menuOpen={menuOpen}
            setMenuState={setMenuState}
          />
        </div>
        <div className="tabmenu-container" id="tabmenu-container">
          <TabMenu
            id="tabmenu"
            className="tabmenu"
            model={items}
            activeIndex={activeIndex}
            onTabChange={(e: TabMenuTabChangeParams) => {
              if (enableContent) {
                if (activeComponent !== 'content') {
                  setActiveComponent('content');
                }
                setActiveIndex(e.index);
                window.location.replace('#' + items[e.index].id);

              } else if (!enableContent) {
                window.alert('Bitte entscheiden Sie zuerst, ob Sie mit dem Setzen von Cookies einverstanden sind!'); setActiveComponent('cookies');
              }
            }}
          />
        </div>
      </div>
    </header>
  );
};

export default SecondHeader;