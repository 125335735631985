import React, { useEffect, useState } from 'react';

import './css_resources/css/App.css';
import '../node_modules/primereact/resources/themes/bootstrap4-light-blue/theme.css';
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import SecondHeader from './Header/SecondHeader/SecondHeader';
import FirstHeader from './Header/FirstHeader/FirstHeader';
import Footer from './Footer/Footer';
import News from './Content/News';
import Contact from './Content/Contact';
import OpeningHours from './Content/OpeningHours';
import Start from './Start/Start';
import SwitchComponents from './SwitchComponents';
import Cookies from './Cookies';
import Dsgvo from './Footer/Dsgvo';
import Impressum from './Footer/Impressum';

const App = () => {
  const [activeComponent, setActiveComponent] = useState('cookies');
  const [activeIndex, setActiveIndex] = useState<number>();
  const [enableContent, setEnableContent] = useState<boolean>(false);
  const [enableCookies, setEnableCookies] = useState<boolean>(false);

  useEffect(() => {

    window.location.replace('#start/');

    let allCookies = document.cookie;

    if (allCookies !== '') {
      if (allCookies.indexOf(';') !== -1) {
        const activeComponentValue = allCookies.substring(allCookies.indexOf('activeComponent={') + 17, allCookies.indexOf('}}'))
        setActiveComponent(activeComponentValue);
        setActiveIndex(0);
        setEnableContent(activeComponentValue === 'cookies' ? false : true);

        if (activeComponentValue !== 'cookies') {
          document.getElementById('app')?.classList.add('active');
        }

      } else {
        const activeComponentValue = allCookies.substring(allCookies.indexOf('activeComponent={') + 20, allCookies.length)
        setActiveComponent(activeComponentValue);
        setActiveIndex(0);
        setEnableContent(activeComponentValue === 'cookies' ? false : true);

        if (activeComponentValue !== 'cookies') {
          document.getElementById('app')?.classList.add('active');
        }
      }

      allCookies = allCookies.substring(allCookies.indexOf(';') + 1, allCookies.length);

      if (allCookies.indexOf(';') !== -1) {
        const cookies = allCookies.substring(allCookies.indexOf('enableCookies={') + 15, allCookies.indexOf('}'));
        const cookiesBool = cookies === 'true' ? true : false;
        setEnableCookies(cookiesBool);

      } else {
        const cookies = allCookies.substring(allCookies.indexOf('enableCookies={') + 15, allCookies.length - 1)
        const cookiesBool = cookies === 'true' ? true : false;
        setEnableCookies(cookiesBool);
      }
    }
    document.documentElement.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
    [],
  );

  if (activeComponent === 'impressum' || activeComponent === 'dsgvo') {
    document.getElementById('start')?.classList.add("invisible");
    document.getElementById('start-content')?.classList.add("invisible");
    document.getElementById('welcome-container')?.classList.add("invisible");

  } else {
    document.getElementById('start')?.classList.remove("invisible");
    document.getElementById('start-content')?.classList.remove("invisible");
    document.getElementById('welcome-container')?.classList.remove("invisible");
  }

  const onMapClick = () => {
    return window.confirm('\nWollen Sie wirklich diese Seite verlassen?\n\nZieladresse: \nwww.google.com/maps') ? window.open("https://maps.google.com/maps?q=35451%20Pohlheim%20Holzheim,%20Zum%20Hasenberg%201") : undefined;
  }

  return (
    <div className="inner-body">
      <FirstHeader />
      <SecondHeader
        enableContent={enableContent}
        activeIndex={activeIndex}
        activeComponent={activeComponent}
        setActiveIndex={setActiveIndex}
        setActiveComponent={setActiveComponent}
      />
      <div className="app" id="app" >
        <div className="start" id="start" >
          <Start />
        </div>
        <SwitchComponents active={activeComponent} >
          <div className="cookies" id="cookies" >
            <Cookies
              setEnableCookies={setEnableCookies}
              setActiveComponent={setActiveComponent}
              setEnableContent={setEnableContent}
            />
          </div>
          <div className="content-container" id="content" >
            <News id="news" />
            <OpeningHours id="opening-hours" />
            <Contact id="contact" onMapClick={onMapClick} enableCookies={enableCookies} activeIndex={activeIndex} />
          </div>
          <Impressum setActiveComponent={setActiveComponent} className="impressum" id="impressum" enableContent={enableContent} />
          <Dsgvo setActiveComponent={setActiveComponent} className="dsgvo" id="dsgvo" enableContent={enableContent} />
        </SwitchComponents>
        <Footer
          onMapClick={onMapClick}
          setActiveComponent={setActiveComponent}
          setActiveIndex={setActiveIndex}
          enableCookies={enableCookies}
        />
      </div>
    </div>
  );
};

export default App;