import { Button } from 'primereact/button';
import React, { ComponentState } from 'react';

import TextLoader from './TextLoader';

import CookiesTxt from './css_resources/text/cookies.json';

import './css_resources/css/Cookies.css';

type Props = {
    setEnableContent: ComponentState,
    setEnableCookies: ComponentState,
    setActiveComponent: ComponentState,
}

const Cookies = ({ setEnableContent, setEnableCookies, setActiveComponent }: Props) => {

    const bannerCookiesAccept = () => {
        document.cookie = 'activeComponent={content}}; SameSite=Strict';
        document.cookie = 'enableCookies={true}; SameSite=Strict';
    };

    const bannerCookiesAcceptNot = () => {
        document.cookie = 'activeComponent={content}}; SameSite=Strict';
        document.cookie = 'enableCookies={false}; SameSite=Strict';
    }

    return (
        <div className="cookies-inner-container" >
            <div className="cookies-banner" >
                <div className="banner-strip" ></div>
                <div className="banner-body" >
                    <div className="banner-label">
                        {TextLoader(CookiesTxt.label, '', 0, 1)}
                    </div>
                    <div className="banner-text" >
                        {TextLoader(CookiesTxt.CookiesTxt.Cookies, '', 0, 1)}
                        <br></br>
                        {TextLoader(CookiesTxt.CookiesTxt.Cookies, '', 1, 2)}
                        <br></br>
                        {TextLoader(CookiesTxt.CookiesTxt.Cookies, '', 2, 3)}
                    </div>
                    <div className="cookies-button-container">
                        <div className="cookies-every-necessary-button-container">
                            <div className="cookies-every-container" >
                                <Button
                                    onClick={() => {
                                        setEnableContent(true);
                                        setEnableCookies(true);
                                        setActiveComponent('content');
                                        document.documentElement.scrollTop = 0;
                                        bannerCookiesAccept();
                                        document.getElementById('app')?.classList.add('active');
                                    }}
                                    onTouchStart={() => {
                                        setEnableContent(true);
                                        setEnableCookies(true);
                                        setActiveComponent('content');
                                        document.documentElement.scrollTop = 0;
                                        bannerCookiesAccept();
                                        document.getElementById('app')?.classList.add('active');
                                    }}
                                    className="cookies-button-every"
                                    label="Alle akzeptieren"
                                />
                            </div>
                            <div className="cookies-necessary-container">
                                <Button
                                    onClick={() => {
                                        setActiveComponent('content');
                                        setEnableCookies(false); setEnableContent(true);
                                        bannerCookiesAcceptNot();
                                        document.documentElement.scrollTop = 0;
                                        document.getElementById('app')?.classList.add('active');
                                    }}
                                    onTouchStart={() => {
                                        setActiveComponent('content');
                                        setEnableCookies(false);
                                        setEnableContent(true);
                                        bannerCookiesAcceptNot();
                                        document.documentElement.scrollTop = 0;
                                        document.getElementById('app')?.classList.add('active');
                                    }}
                                    className="cookies-button-necessary"
                                    label="Nur notwendige Cookies"
                                />
                            </div>
                        </div>
                        <div className="cookies-dsgvo-impressum-container">
                            <Button
                                onClick={() => {
                                    setActiveComponent('dsgvo'); 
                                    document.documentElement.scrollTop = 0;
                                    window.location.replace('#DSGVO/');
                                    document.getElementById('app')?.classList.add('active');
                                }}
                                onTouchStart={() => {
                                    setActiveComponent('dsgvo');
                                    document.documentElement.scrollTop = 0; 
                                    window.location.replace('#DSGVO/');
                                    document.getElementById('app')?.classList.add('active');
                                }}
                                className="cookies-button-dsgvo"
                                label="Datenschutzerklärung"
                            />
                            <Button
                                onClick={() => { 
                                    setActiveComponent('impressum'); 
                                    document.documentElement.scrollTop = 0; 
                                    window.location.replace('#Impressum/'); 
                                    document.getElementById('app')?.classList.add('active'); }}
                                onTouchStart={() => { 
                                    setActiveComponent('impressum'); 
                                    document.documentElement.scrollTop = 0; 
                                    window.location.replace('#Impressum/'); 
                                    document.getElementById('app')?.classList.add('active'); }}
                                className="cookies-button-impressum"
                                label="Impressum"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cookies;