import React from 'react';

import '../../css_resources/css/FirstHeader.css';

const FirstHeader = () => {
    return (
        <header className="first-header" id="first-header" >
            <div className="header-contact">
                <div className="phone-container pi pi-phone">
                    &nbsp;
                    <a href="tel: +49-6004-1233">06004-1233</a>
                    &nbsp;
                </div>
                <div className="mail-container pi pi-envelope">
                    &nbsp;
                    <a href="mailto: info@kleintierpraxis-anettejaeger.de">info@kleintierpraxis-anettejaeger.de</a>
                </div>
            </div>
        </header>
    );
};

export default FirstHeader;