import React, { useEffect } from 'react';

import TextLoader from '../TextLoader';

import txt from '../css_resources/text/txt.json';

import '../css_resources/css/News.css';


type Props = {
    id: string,
};

const News = ({ id }: Props) => {

    useEffect(() => {
        TextLoader(txt.infos, 'first-text', 1, 2)
    },
        [],
    );


    return (
        <div className="news" id={id}>
            <div className="news-container">
                <div className="news-label" id="news-label"><b>{TextLoader(txt.infos, '', 0, 1)}</b></div>
                <div id="first-text"></div>

                <ul>
                    <li className="crit-li">
                        <div className="crit headline">{TextLoader(txt.infos, '', 2, 3)}</div>
                    </li>
                    <div className="space"></div>
                    <li className="crit-li">
                        <div className="crit headline">{TextLoader(txt.infos, '', 3, 4)}</div>
                    </li>
                    <li className="crit-li">
                        <div className="crit">{TextLoader(txt.infos, '', 4, 5)}</div>
                    </li>
                </ul>
                <div className="questions" id="questions">{TextLoader(txt.infos, '', 5, 6)}</div>
                <div className="space"></div>
                <div className="questions" id="questions">{TextLoader(txt.infos, '', 6, 7)}</div>
            </div>
        </div>
    );
};

export default News;