import React, { ComponentState, useCallback, useEffect } from 'react';
import { Button } from 'primereact/button';

import '../../css_resources/css/HamburgerMenu.css';

type Props = {
  menuOpen: boolean;
  setMenuState: ComponentState;
}


const Menu = ({ menuOpen, setMenuState }: Props) => {

  const onButtonClick = useCallback((e: any) => {
    const secondHeaderContainer = document.getElementById('second-header-container');
    const headerWithTabmenu = document.getElementById('header-with-tabmenu')
    const secondHeader = document.getElementById('second-header');
    const tabmenuContainer = document.getElementById('tabmenu-container');

    if (menuOpen) {

      secondHeaderContainer?.classList.remove('active');
      headerWithTabmenu?.classList.remove('active');
      secondHeader?.classList.remove('active');
      tabmenuContainer?.classList.remove('active');

      if (e.target.className.includes('hamburger-button')) {
        e.target.classList.remove('active');

      } else if (e.target.className.includes('bar')) {
        e.target.parentNode.classList.remove('active');
      }
      setMenuState(false);

    } else {

      secondHeaderContainer?.classList.add('active');
      headerWithTabmenu?.classList.add('active');
      secondHeader?.classList.add('active');
      tabmenuContainer?.classList.add('active');

      if (e.target.className.includes('hamburger-button')) {
        e.target.classList.add('active');

      } else if (e.target.className.includes('bar')) {
        e.target.parentNode.classList.add('active');
      }

      setMenuState(true);
    }
  },
    [menuOpen, setMenuState],
  );

  useEffect(() => {
    document.getElementById('hamburger-button')?.addEventListener('click', onButtonClick);

    return () => {
      document.getElementById('hamburger-button')?.removeEventListener('click', onButtonClick);
    }
  },
    [onButtonClick],
  );


  return (
    <div className="menu-container">
      <div className="hamburger-container">
        <Button
          className="hamburger-button"
          id="hamburger-button"
        >
          <span className='bar1 bar'></span>
          <span className='bar2 bar' ></span>
          <span className='bar3 bar'></span>
        </Button>
      </div>
    </div>

  );
};

export default Menu;