import React from 'react';

import TextLoader from '../TextLoader';

import Map from './Map'

import txt from '../css_resources/text/txt.json';

import '../css_resources/css/Contact.css';

type Props = {
    id: string,
    onMapClick: () => void,
    enableCookies: boolean,
    activeIndex: number | undefined,
};

const Contact = ({ onMapClick, id, enableCookies, activeIndex }: Props) => {

    return (
        <div className="contact" id={id}>
            <div className='contact-with-map'>
                <div className="contact-container">
                    {enableCookies ?
                        <div className="contact-adress-container .contact-adress-button cursor-pointer" onClick={onMapClick}>
                            <div className="contact-label" >{TextLoader(txt.kontakt, '', 0, 1)}</div>
                            <div className="contact-adress contact-praxis" >{TextLoader(txt.kontakt, '', 1, 2)}</div>
                            <div className="contact-adress contact-street" >{TextLoader(txt.kontakt, '', 2, 3)}</div>
                            <div className="contact-adress cotact-location" >{TextLoader(txt.kontakt, '', 3, 4)}</div>
                        </div>
                        :
                        <div className="contact-adress-container">
                            <div className="contact-label" >{TextLoader(txt.kontakt, '', 0, 1)}</div>
                            <div className="contact-adress contact-praxis" >{TextLoader(txt.kontakt, '', 1, 2)}</div>
                            <div className="contact-adress contact-street" >{TextLoader(txt.kontakt, '', 2, 3)}</div>
                            <div className="contact-adress cotact-location" >{TextLoader(txt.kontakt, '', 3, 4)}</div>
                        </div>
                    }
                    <div className="phone-and-mail">
                        <div className="contact-phone-mail-container">
                            <div className=" pi pi-phone" ></div>
                            <a className="contact-adress contact-phone cursor-pointer" href="tel: +49-6004-1233" >&nbsp;{TextLoader(txt.kontakt, '', 4, 5)}</a>
                        </div>
                        <div className="contact-phone-mail-container" >
                            <div className=" pi pi-envelope" ></div>
                            <a className="contact-adress contact-mail cursor-pointer" href="mailto: info@kleintierpraxis-anettejaeger.de" >&nbsp;{TextLoader(txt.kontakt, '', 5, 6)}</a>
                        </div>
                    </div>
                </div>
                <div className="map-container">
                    <Map
                        title="Google Maps, Kleintierpraxis Anette Jäger, Zum Hasenberg 1, 35415 Pohlheim"
                        enableCookies={enableCookies}
                        id="map"
                        activeIndex={activeIndex}
                    />
                </div>
            </div>
        </div>
    );
};

export default Contact;