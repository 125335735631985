import React from 'react';

type Props = {
  active: string,
  children: JSX.Element[],
}

const SwitchComponents = ({ active, children }: Props): JSX.Element => {
  const currentComponent = children.filter(child => child.props.id === active);
  if (currentComponent.length !== 0) {
   return currentComponent[0];
  } 
  return <div>Komponente nicht gefunden</div>;
}

export default SwitchComponents;