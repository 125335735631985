import { Button } from 'primereact/button';
import React, { ComponentState } from 'react';

import '../css_resources/css/Impressum.css'

type Props = {
    setActiveComponent: ComponentState;
    enableContent: boolean,
    id: string,
    className: string,
}

const Impressum = ({ setActiveComponent, enableContent, id, className }: Props) => {
    return (
        <div className={className} id={id}>
            <div className="impressum-back-button-top-container" >
                <Button
                    onClick={() => {
                        enableContent ? setActiveComponent('content') : setActiveComponent('cookies');
                        enableContent ? document.getElementById('app')?.classList.add('active') : document.getElementById('app')?.classList.remove('active');
                        document.documentElement.scrollTop = 0;
                        window.location.replace('#start/')
                    }}
                    onTouchStart={() => {
                        enableContent ? setActiveComponent('content') : setActiveComponent('cookies');
                        enableContent ? document.getElementById('app')?.classList.add('active') : document.getElementById('app')?.classList.remove('active');
                        document.documentElement.scrollTop = 0;
                        window.location.replace('#start/')
                    }}
                    className="impressum-back-button-top"
                    label={enableContent ? "Zurück zum Start" : "Zum Cookies-Banner"}
                />
            </div>

            <div className="impressum-label" >Impressum</div>

            <div className="impressum-container" >
                <div className="impressum-text" >
                    <h2 className="impressum-headline" >Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                    <p>Kleintierpraxis Anette J&auml;ger<br />
                        Zum Hasenberg, 1<br />
                        35415 Pohlheim</p>

                    <h2 className="impressum-headline" >Kontakt</h2>
                    <p>Telefon: +49 (0) 6004  1233<br />
                        Telefax: +49 (0) 6004 2825<br />
                        E-Mail: info@kleintierpraxis-anettejaeger.de</p>

                    <h2 className="impressum-headline" >Umsatzsteuer-ID</h2>
                    <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
                        DE112578546</p>

                    <h2 className="impressum-headline" >Aufsichtsbeh&ouml;rde</h2>
                    <p>Regierungspräsidium Gießen<br />
                        Landgraf-Philipp-Platz 1-7<br />
                        35390 Gießen</p>


                    <p><a href="https://rp-giessen.hessen.de/umwelt-natur/veterin%C3%A4rwesen" target="_blank" rel="noopener noreferrer">https://rp-giessen.hessen.de/umwelt-natur/veterinärwesen</a></p>

                    <h2 className="impressum-headline" >Berufsbezeichnung und berufsrechtliche Regelungen</h2>
                    <p>Berufsbezeichnung: Tier&auml;rztin</p>
                    <p>Verliehen in: Deutschland</p>
                    
                    <p>Es gelten folgende berufsrechtliche Regelungen:</p>
                    <p>Bundes-Tier&auml;rzteverordnung<br />
                        einsehbar unter: <a href="https://www.gesetze-im-internet.de/bt_o/BJNR004160965.html" target="_blank" rel="noopener noreferrer">https://www.gesetze-im-internet.de/bt_o/BJNR004160965.html</a></p>
                    <p>Gesetz &uuml;ber das Berufsrecht und die Kammern der Heilberufe (Heilberufsgesetz)<br />
                        einsehbar unter: <a href="https://www.rv.hessenrecht.hessen.de/bshe/document/jlr-%C3%84WeitBiGHEV15P1" target="_blank" rel="noopener noreferrer">https://www.rv.hessenrecht.hessen.de/bshe/document/jlr-%C3%84WeitBiGHEV15P1</a></p>
                    <h2 className="impressum-headline" >Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h2>
                    <p>Name und Sitz des Versicherers:<br />
                        ALTE LEPZIGER Vers. AG<br />
                        Alter Leipziger-Platz 1<br />
                        61440 Oberursel (Taunus)</p>
                    <p>Geltungsraum der Versicherung: Deutschland</p>

                    <h2 className="impressum-headline" >Zuständige Kammer und Schlichtungsstelle im Streitfall.</h2>
                    <p>Zust&auml;ndige Kammer:<br />
                        Landestier&auml;rztekammer Hessen<br />
                        Körperschaft des öffentlichen Rechts<br />
                        Bahnhofstra&szlig;e 13<br />
                        65527 Niedernhausen <br />
                        Telefon: +49 (0) 6127 9075 0</p>

                        <p>Geltungsraum der Versicherung: Deutschland</p>

                        <p><a href="https://www.ltk-hessen.de" target="_blank" rel="noopener noreferrer">https://www.ltk-hessen.de</a></p>

                    <p>Der Schlichtungsausschuss der Landestierärztekammer Hessen hat die Aufgabe, bei Streitigkeiten, die sich aus der Berufsausübung zwischen Tierärzten und Dritten ergeben, eine Schlichtung zu versuchen. Er wird von Amts wegen oder auf Antrag tätig. Soweit Dritte beteiligt sind, aber nur auf deren Antrag oder mit deren ausdrücklicher Zustimmung.</p>
                    <br />
                    <p>Das Impressum wurde mit Hilfe des Impressum-Generators von <a href="https://www.e-recht24.de">e-recht24.de</a> erstellt.</p>
                </div>
            </div>

            <div className="impressum-back-button-bottom-container">
                <Button
                    onClick={() => {
                        enableContent ? setActiveComponent('content') : setActiveComponent('cookies')
                        enableContent ? document.getElementById('app')?.classList.add('active') : document.getElementById('app')?.classList.remove('active');
                        document.documentElement.scrollTop = 0;
                        window.location.replace('#start/')
                    }}
                    onTouchStart={() => {
                        enableContent ? setActiveComponent('content') : setActiveComponent('cookies');
                        enableContent ? document.getElementById('app')?.classList.add('active') : document.getElementById('app')?.classList.remove('active');
                        document.documentElement.scrollTop = 0;
                        window.location.replace('#start/')
                    }}
                    className="impressum-back-button-bottom"
                    label={enableContent ? "Zurück zum Start" : "Zum Cookies-Banner"}
                />
            </div>
        </div>
    )
}

export default Impressum;