import React, { ComponentState, useEffect, useState } from 'react';

import '../css_resources/css/Footer.css';

type Props = {
    onMapClick: () => void,
    setActiveComponent: ComponentState,
    setActiveIndex: ComponentState,
    enableCookies: boolean,
}

const Footer = ({ onMapClick, setActiveComponent, setActiveIndex, enableCookies }: Props) => {
    const [windowWidth, setWindowWidth] = useState('');

    const checkWindowWidth = () => {
        setWindowWidth(window.innerWidth <= 1024 ? '' : ' | ');
    };

    useEffect(() => {
        window.addEventListener('resize', checkWindowWidth);
        checkWindowWidth();
    },
        [],
    );

    return (
        <footer className="footer" >
            <div className="adress">
                <div className="adress-head">
                    {enableCookies ?
                        <b><div onClick={onMapClick}>
                            Kleintierpraxis Anette Jäger&nbsp;</div></b>
                        :
                        <b><div>
                            Kleintierpraxis Anette Jäger&nbsp;</div></b>
                    }
                </div>
                {windowWidth}
                <div>
                    &nbsp;Zum Hasenberg 1&nbsp;
                </div>
                {windowWidth}
                <div>
                    &nbsp;35415 Pohlheim&nbsp;
                </div>
            </div>
            <div className="footer-contact">
                <div className="footer-phone-container pi pi-phone">
                    &nbsp;
                    <a href="tel: +49-6004-1233">06004-1233</a>
                </div>
                &nbsp;
                {windowWidth}
                <div className="footer-mail-container pi pi-envelope">
                    &nbsp;
                    <a href="mailto: info@kleintierpraxis-anettejaeger.de">info@kleintierpraxis-anettejaeger.de</a>
                </div>
                &nbsp;
                {windowWidth}
            </div>

            <div className="impressum-dsgvo-container" >
                <div className="impressum-button-container">
                    <div
                        className="impressum-button"
                        id="impressumm-button"
                        onClick={() => { 
                            setActiveComponent('impressum'); 
                            setActiveIndex(-1); document.documentElement.scrollTop = 0; 
                            window.location.replace('#impressum/') }}
                    >
                        Impressum
                    </div>
                </div>
                &nbsp;
                {windowWidth}
                &nbsp;
                <div className="dsgvo-button-container">
                    <div
                        className="dsgvo-button"
                        id="dsgvo-button"
                        onClick={() => { setActiveComponent('dsgvo'); setActiveIndex(-1); document.documentElement.scrollTop = 0; window.location.replace('#dsgvo/') }}
                    >
                        Datenschutz
                    </div>
                </div>
            </div>
        </footer >
    );
};

export default Footer;