import React, { useEffect } from 'react';

import TextLoader from '../TextLoader';

import txt from '../css_resources/text/txt.json';

import '../css_resources/css/Start.css';

const Start = () => {

    useEffect(() => {
        TextLoader(txt.willkommen, 'welcome-label', 0, 1);
        TextLoader(txt.willkommen, 'welcome', 1, 2);
    },
        [],
    );

    return (
        <div className="start-content" id="start-content">
            <div className="welcome-container" id="welcome-container">
                <div className="welcome-label" id="welcome-label"></div>
                <div className="welcome" id="welcome"></div>
                <div className="welcome-text-container" >
                    <div className="welcome-text-one" >{TextLoader(txt.willkommen, '', 2, 3)}</div>
                    <div className="welcome-text-two" >{TextLoader(txt.willkommen, '', 3, 4)}</div>
                    <div className="welcome-text-three" >{TextLoader(txt.willkommen, '', 4, 5)}</div>
                </div>
            </div>
        </div>
    );
};

export default Start;